import React from "react"
import styled from "@emotion/styled"

import { dimensions, fonts, breakpoints } from "../../utils/styles"

const PageContent = ({ children }) => (
  <ContentWrapper>{children}</ContentWrapper>
)

const ContentWrapper = styled.div`
  max-width: ${dimensions.bounds}px;
  font-family: ${fonts.heading};
  margin: 0 auto;
  padding: 40px 20px 60px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 80px 20px 100px;
  }
`

export default PageContent
