import React, { useContext } from "react"
import styled from "@emotion/styled"

import { fonts } from "../../utils/styles"
import { UIContext } from "../../context/UIContext"

const TransError = () => {
  const { state } = useContext(UIContext)

  return (
    <>
      {state.baseLang.code === "zh" ? (
        <ErrorMessage>
          <span>注意!</span> Sorry, these translations are coming soon.
        </ErrorMessage>
      ) : null}
    </>
  )
}

const ErrorMessage = styled.div`
  background: #000000;
  color: white;
  padding: 15px 20px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  font-size: 1.15em;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.heading};

  span {
    margin-right: 20px;
    font-weight: bold;
  }
`

export default TransError
